import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
      <div
        className="center mw8"
      >
        <div className="pl3 pa3 w-70-l center">
          <h1>404 (Not found)</h1>
          <p>Let&apos;s hope it gets better soon.</p>
        </div>
      </div>
  </Layout>
)

export default NotFoundPage
